<template>
  <v-container>
    <div v-if="execution">
      <v-row>
        <v-col cols="12">
          <ResultCard
            :execution="execution"
            :outputs="outputs"
            @downloadOutputFile="downloadOutputFile"
            @refresh="updateExecution"
          />
        </v-col>
      </v-row>

      <v-row v-if="showSourceSmiles && execution['status'] == 'COMPLETE'">
        <v-col cols="12">
          <SourceSmiles
            :source-compounds="sourceSmiles.items"
            :outputs="outputs"
            @downloadOutputFile="downloadOutputFile"
          />
        </v-col>
      </v-row>

      <v-row v-if="showFilter">
        <v-col cols="12">
          <FilterCard
            :execution="execution"
            :filters="filters"
            :has-filtered-results="mainContent
              && mainContent.items
              && mainContent.items.length > 0"
            @selectFilters="selectFilters"
            @downloadFilteredResult="downloadFilteredResult"
          />
        </v-col>
      </v-row>

      <v-row v-if="execution['status'] == 'COMPLETE'">
        <v-col cols="12">
          <CsvItemList :csv-data="mainContent" csv-item-type="Molecule" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import CsvItemList from '@/components/CsvItemList';
import SourceSmiles from '@/components/SourceSmiles';
import ResultCard from '@/components/Yamanishi/ResultCard';
import FilterCard from '@/components/Yamanishi/FilterCard';
import consts from '@/store/consts';
import { showWaitingDialog } from '@/mixins/utils';

export default {
  name: 'ResultViewType1',
  components: {
    CsvItemList,
    ResultCard,
    FilterCard,
    SourceSmiles
  },
  props: {
    showSourceSmiles: {
      type: Boolean,
      default: false
    },
    showFilter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      timer: null,
      execution: null,
      lastUpdated: null,
      outputs: [],
      mainContent: null,
      sourceSmiles: { 'columns': [], 'items': [] },
      isDisplayedDialog: false,
      filters: [consts.Filters.PAINS, consts.Filters.UNKNOWN_RING, consts.Filters.MOLSKILL]
    };
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    this.observeExecution();
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    updateExecution() {
      const self = this;
      self.api.getExecution(self.id, function(execution) {
        self.lastUpdated = Date.now();
        self.execution = execution;
        if (execution.end_at) {
          clearInterval(self.timer);
          if (execution.status === 'COMPLETE') {
            self.setOutput();
            self.loadCompounds();
            if (self.showSourceSmiles) {
              self.loadSourceSmiles();
            }
          }
        } else {
          if (!self.isDisplayedDialog) {
            showWaitingDialog();
            self.isDisplayedDialog = true;
          }
        }
      }, function(error) {
        console.log(error);
      });
    },
    observeExecution() {
      this.updateExecution();
      this.timer = setInterval(this.updateExecution, 30 * 1000);
    },
    setOutput() {
      const config = JSON.parse(this.execution.module.config);
      this.outputs = config.output;
    },
    loadCompounds() {
      const self = this;
      this.api.getFilteredHitGenerationResult(
        self.id,
        self.filters.map((filter) => filter.predicted_value_name),
        function(compounds) {
          self.mainContent = compounds;
        }, function(error) {
          console.log(error);
        });
    },
    loadSourceSmiles() {
      const self = this;
      this.api.getHitGenerationSourceSmiles(
        self.id,
        function(compounds) {
          console.log(compounds);
          self.sourceSmiles = compounds;
        }, function(error) {
          console.log(error);
        });
    },
    downloadOutputFile(file) {
      this.api.downloadExecutionFile(this.id, file);
    },
    selectFilters(selectedFilters) {
      const self = this;
      this.api.getFilteredHitGenerationResult(
        self.id,
        selectedFilters,
        function(mainContent) {
          self.mainContent = mainContent;
        }, function(error) {
          console.log(error);
        });
    },
    downloadFilteredResult(selectedFilters) {
      this.api.downloadExecutionFile(this.id, this.outputs[0], selectedFilters);
    }
  }
};
</script>
<style scoped>
    button {
        border: solid 1px;
        padding: 5px;
    }
</style>
