const Filters = {
  'PAINS': {
    'name': 'PAINS',
    'predicted_value_name': 'pains'
  },
  'UNKNOWN_RING': {
    'name': 'Unknown ring ChEMBL 29',
    'predicted_value_name': 'unknown_ring'
  },
  'MOLSKILL': {
    'name': 'MolSkill',
    'predicted_value_name': 'molskill'
  }
};

export default {
  Filters: Filters
};
