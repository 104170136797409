<template>
  <v-data-table
    class="elevation-1"
    :headers="columns"
    :items="filteredItems"
    item-key="id"
    :items-per-page="20"
    fixed-header
    hide-default-footer
  >
    <template #top="{ pagination, options, updateOptions }">
      <v-data-footer
        :pagination="pagination"
        :options="options"
        :items-per-page-options="itemsPerPageOptions"
        :items-per-page-text="itemsPerPageText"
        :show-first-last-page="true"
        @update:options="updateOptions"
      />
    </template>
    <template #[`body.prepend`]>
      <tr>
        <td v-for="column of columns" :key="column.text">
          <div v-if="column.filterable">
            <v-row
              v-if="column.numeric"
              style="max-width: 200px;"
            >
              <v-col cols="6">
                <v-text-field
                  v-model="numericFilters[column.value].min"
                  placeholder="min"
                  dense
                  hide-details
                  @input="applyFilters"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="numericFilters[column.value].max"
                  placeholder="max"
                  dense
                  hide-details
                  @input="applyFilters"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-else
              v-model="filters[column.value]"
              :placeholder="column.value"
              dense
            />
          </div>
        </td>
      </tr>
    </template>
    <template #[`item.ID`]="{ item }">
      <a v-if="model == 'tp'" :href="`${item.Link}`" target="_blank">
        {{ item.ID }}
      </a>
      <a v-else-if="model == 'pp'" :href="`${item.URL}`" target="_blank">
        {{ item.ID }}
      </a>
      <span v-else v-html="item.ID" />
    </template>
    <template #[`item.Structure`]="{ item }">
      <span v-html="item.Structure" />
    </template>
    <template #[`item.view`]="{ item }">
      <v-btn
        color="primary"
        class="mx-2"
        elevation="3"
        fab
        x-small
        @click="viewReaction(item)"
      >
        <v-icon>mdi-eye</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { ITEMS_PER_PAGE_OPTIONS } from '@/env';

export default {
  name: 'CsvItemList',
  components: {
  },
  props: {
    csvData: {
      type: Object,
      default: () => null
    },
    csvItemType: {
      type: String,
      default: 'Protein'
    },
    model: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      columns: [],
      items: [],
      filteredItems: [],
      filters: {},
      numericFilters: {},
      itemsPerPageText: this.paginationText(this.csvItemType),
      itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
      searchExactMatch: ['pains', 'unknown_ring', 'molskill', 'Regulation type'],
    };
  },
  watch: {
    csvData: {
      handler: function() {
        this.columns = [];
        this.items = [];
        this.filteredItems = [];
        this.filters = {};
        if (this.csvData == null) {
          return;
        }
        for (const i in this.csvData.columns) {
          const column = this.csvData.columns[i];
          if (column === 'Link' || column === 'URL' || column === 'Source_mol') {
            continue;
          }
          const info = {
            text: column,
            value: column,
            filterable: column.toLowerCase() !== 'structure',
            numeric: column.toLowerCase() !== 'pains' && this.csvData.items.every(
              item => !isNaN(parseFloat(item[i]))
            )
          };
          this.columns.push(info);
          if (column === 'Predicted Reaction') {
            this.columns.push({
              text: '',
              value: 'view',
              filterable: false
            });
          }
        }
        for (const i in this.csvData.items) {
          const item = this.csvData.items[i];
          const entry = {};
          for (const index in item) {
            const key = this.csvData.columns[index];
            entry[key] = String(item[index]);
          }
          this.items.push(entry);
          this.filteredItems.push(entry);
        }
        this.numericFilters = this.columns
          .filter(column => column.numeric)
          .reduce((acc, column) => {
            acc[column.value] = { min: '', max: '' };
            return acc;
          }, {});
      },
      deep: true
    },
    filters: {
      handler: function() {
        this.applyFilters();
      },
      deep: true
    },
    numericFilters: {
      handler: function() {
        this.applyFilters();
      },
      deep: true
    }
  },
  methods: {
    paginationText(type) {
      return `${type}s per page`;
    },
    fieldFn(column) {
      function fieldFn(rowObj) {
        return rowObj[column];
      }
      return fieldFn;
    },
    viewReaction(item) {
      this.$emit('viewReaction', item['Predicted Reaction']);
    },
    applyFilters() {
      this.filteredItems = this.items.filter((item) =>
        Object.entries(this.filters).every(([k, v]) => {
          if (this.searchExactMatch.includes(k)) {
            return v === '' || item[k] === v;
          } else {
            return v === '' || item[k].includes(v);
          }
        }) &&
        Object.entries(this.numericFilters).every(([k, v]) =>
          (v.min === '' || parseFloat(item[k]) >= parseFloat(v.min)) &&
          (v.max === '' || parseFloat(item[k]) <= parseFloat(v.max))
        )
      );
    }
  }
};
</script>
